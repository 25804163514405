
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class IconChipWithTooltip extends Vue {
    @Prop() icon?: string

    @Prop() tooltipText!: string

    @Prop({default: "#ea5b0b"}) chipBorderColor!: string

    @Prop({default: "right"}) location!: string

    get top() { return (this.location == "top") }
    get bottom() { return (this.location == "bottom") }
    get left() { return (this.location == "left") }
    get right() { return (this.location == "right") }
}
